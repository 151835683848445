import clsx from 'clsx';
import Link from 'next/link';
import React, { ComponentProps } from 'react';

export type ButtonProps = {
  className?: string;
  fullWidth?: boolean;
  isAttached?: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color?: 'white' | 'brand' | 'black' | 'facebookBlue' | 'red';
  children: React.ReactNode;
} & (
  | ComponentProps<'button'>
  | {
      href: string;
    }
);

export default function Button({
  className,
  fullWidth,
  children,
  color = 'brand',
  startIcon,
  endIcon,
  isAttached = false,
  disabled = false,
  ...restProps
}: ButtonProps) {
  const hasIcon = !!(startIcon || endIcon);
  const finalClassName = clsx(
    'transition-colors',
    'h-12',
    'rounded shadow',
    'inline-flex items-center',
    'whitespace-nowrap',
    fullWidth && 'w-full',
    {
      'px-4 justify-center': !hasIcon,
      'px-1 justify-start': hasIcon,
      'rounded-l-none': isAttached,
      'opacity-70 cursor-default': disabled,
      'text-white bg-brand hover:bg-brand-600 ': color === 'brand',
      'text-white bg-black': color === 'black',
      'text-white bg-facebook-blue': color === 'facebookBlue',
      'text-white bg-red-600': color === 'red',
      'bg-white text-gray-800 hover:bg-gray-200 ': color === 'white',
    },
    className,
  );
  if ('href' in restProps) {
    const { href, ...restPropsWithoutHref } = restProps;
    if (disabled) {
      return <div className={finalClassName}>{children}</div>;
    }
    return (
      <Link href={href}>
        <a className={finalClassName} {...restPropsWithoutHref}>
          {children}
        </a>
      </Link>
    );
  }
  const type = restProps.type || 'button';
  return (
    <button
      className={finalClassName}
      type={type}
      {...restProps}
      disabled={disabled}
    >
      {startIcon && (
        <span className="inline-block w-12 flex justify-center">
          {startIcon}
        </span>
      )}
      <div className={clsx({ 'flex justify-center w-full': hasIcon })}>
        {children}
      </div>
      {endIcon && (
        <span className="inline-block w-12 flex justify-center">{endIcon}</span>
      )}
    </button>
  );
}
