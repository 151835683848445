import clsx from 'clsx';
import React from 'react';
import { User } from 'react-feather';

export type UserAvatarProps = {
  color?: 'white' | 'brand';
  imageSrc?: string;
};

export default function UserAvatar({
  color = 'brand',
  imageSrc,
}: UserAvatarProps) {
  return (
    <div
      className={clsx(
        'rounded-full w-10 h-10 flex items-center justify-center overflow-hidden',
        {
          'bg-brand-500 text-white': !imageSrc && color === 'brand',
          'bg-white text-gray-800': !imageSrc && color === 'white',
        },
      )}
    >
      {imageSrc ? <img src={imageSrc} alt="" /> : <User />}
    </div>
  );
}
