import { useState, useMemo } from 'react';
import Head from 'next/head';
import { Box, Check, Info } from 'react-feather';
import { GetStaticProps } from 'next';
import QuestionCard from ':src/modules/questions/QuestionCard';
import Select from ':src/components/Select';
import { Question } from ':src/types';
import NewsletterSignup from ':src/modules/global/NewsletterSignup';
import apiFetch from ':src/utils/apiFetch';
import UserState from ':src/hooks/UserState';
import { DIFFICULTY } from ':src/constants';

export type HomePageProps = {
  questions: Question[];
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const questions = await apiFetch<Question[]>('questions');
  return {
    props: {
      questions: questions!,
    },
  };
};

const options = [
  {
    value: DIFFICULTY.All,
    display: DIFFICULTY.All,
    disabled: false,
  },
  {
    value: DIFFICULTY.Easy,
    display: DIFFICULTY.Easy,
    disabled: false,
  },
  {
    value: DIFFICULTY.Medium,
    display: DIFFICULTY.Medium,
    disabled: false,
  },
  {
    value: DIFFICULTY.Hard,
    display: DIFFICULTY.Hard,
    disabled: false,
  },
];

export default function HomePage({ questions }: HomePageProps) {
  const { user } = UserState.useContainer();
  const [difficulty, setDifficulty] = useState<string>(DIFFICULTY.All);

  const onSelect = (value: string) => {
    setDifficulty(value);
  };

  const filteredQuestions = useMemo(() => {
    return difficulty === DIFFICULTY.All
      ? questions
      : questions.filter((q) => q.difficulty === difficulty);
  }, [difficulty, questions]);

  return (
    <>
      <Head>
        <title>frontendeval</title>
        <meta
          name="description"
          content="Free exercises to practice for modern frontend interviews"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content="frontendeval" />
        <meta
          property="og:description"
          content="Free exercises to practice for modern frontend interviews"
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={process.env.DOMAIN} />

        <meta name="twitter:creator" content="@frontendeval" />
        <link rel="canonical" href={process.env.DOMAIN} />
      </Head>
      <div className="bg-brand-800 text-white mb-16 -mt-24 pt-24 hero">
        <div className="container relative">
          <div className="py-20 max-w-xl">
            <h2 className="text-5xl md:text-6xl font-bold text-gray-200 mb-8">
              Land your next frontend gig
            </h2>
            <p className="text-xl md:text-2xl text-gray-200 mb-8">
              Free exercises to practice for modern frontend development
              interviews
            </p>
          </div>
        </div>
      </div>
      <div className="mb-16 container">
        <div className="text-center max-w-4xl mx-auto mb-16">
          <div className="text-2xl md:text-3xl font-bold mb-8">
            The frontend development interview landscape is evolving
          </div>
          <div className="text-lg text-gray-600">
            Companies are waking up and recognizing that Leetcode-style
            questions assess your frontend skills about as well as questions
            about your favorite breakfast cereal do. Get ahead of the curve by
            training with exercises that mimic the questions asked by top
            Silicon Valley companies and startups.
          </div>
        </div>
        <dl className="grid md:grid-cols-3 gap-8 mb-12 border-b pb-12">
          <dt>
            <div className="rounded bg-brand w-10 h-10 flex items-center justify-center text-white mb-4">
              <Box />
            </div>
            <h3 className="text-lg font-bold mb-4">Practical exercises</h3>
            <p className="text-gray-600">
              Increasingly, frontend interviews resemble your day job. Don’t get
              stuck practicing yesterday’s interview techniques.
            </p>
          </dt>
          <dt>
            <div className="rounded bg-brand w-10 h-10 flex items-center justify-center text-white mb-4">
              <Info />
            </div>
            <h3 className="text-lg font-bold mb-4">Hints to get unstuck</h3>
            <p className="text-gray-600">
              Reveal just the right amount of hints to move you along without
              spoilers.
            </p>
          </dt>
          <dt>
            <div className="rounded bg-brand w-10 h-10 flex items-center justify-center text-white mb-4">
              <Check />
            </div>
            <h3 className="text-lg font-bold mb-4">Additional Challenges</h3>
            <p className="text-gray-600">
              Looking to expand on the question? Try our additional challenges.
            </p>
          </dt>
        </dl>
      </div>

      <div className="container mb-16">
        <div className="flex justify-end flex-row mb-8">
          <Select
            className="basis-full md:basis-3/12"
            options={options}
            onChange={onSelect}
            label="Select Difficulty"
          />
        </div>
        <div className="grid grid-cols-fill-300 gap-8">
          {filteredQuestions.map((question) => (
            <QuestionCard
              question={question}
              key={question.slug}
              complete={!!user?.completedQuestions.includes(question.slug)}
            />
          ))}
        </div>
      </div>
      <NewsletterSignup />
    </>
  );
}
