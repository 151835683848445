import clsx from 'clsx';
import React from 'react';
import Link from 'next/link';
import type { Question } from ':src/types';
import QuestionDetails from './QuestionDetails';

export type QuestionCardProps = {
  question: Question;
  complete: boolean;
};

export default function QuestionCard({
  question,
  complete,
}: QuestionCardProps) {
  const { isNew, title, description, slug } = question;
  const finalClassName = clsx(
    'h-48 bg-white',
    'shadow-md hover:shadow-xl',
    'focus:shadow-xl focus:outline-none',
    'transition-shadow rounded-md',
    'px-6 pt-5 pb-3',
    'border-l-8 border-brand',
    'flex flex-col',
    complete && 'opacity-70',
  );

  const badgeClassName = clsx('badge', complete && 'bg-green-600');
  return (
    <Link href={`/questions/${slug}`}>
      <a className={finalClassName}>
        <h5 className="font-semibold mb-3">
          <div className="flex">
            <div className="flex-grow">{title}</div>
            {(isNew || complete) && (
              <div className="ml-2">
                <span className={badgeClassName}>
                  {complete ? 'Complete' : 'New'}
                </span>
              </div>
            )}
          </div>
        </h5>
        <div className="text-gray-500">{description}</div>
        <div className="mt-auto">
          <QuestionDetails question={question} />
        </div>
      </a>
    </Link>
  );
}
