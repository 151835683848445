import React from 'react';
import { nanoid } from 'nanoid';
import Button from ':src/components/Button';
import { GITHUB_STATE_LOCALSTORAGE_KEY } from ':src/constants';

export type GithubButtonProps = {
  actionText: string;
};

export default function GithubButton({ actionText }: GithubButtonProps) {
  const handleClick = () => {
    const state = nanoid();
    localStorage.setItem(GITHUB_STATE_LOCALSTORAGE_KEY, state);
    const url = new URL('https://github.com/login/oauth/authorize');
    url.searchParams.append(
      'client_id',
      process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID!,
    );
    url.searchParams.append(
      'redirect_uri',
      `${window.location.origin}/github-callback`,
    );
    url.searchParams.append('scope', 'user:email');
    url.searchParams.append('state', state);
    url.searchParams.append('allow_signup', 'false');
    window.location.href = url.href;
  };

  return (
    <Button
      fullWidth
      startIcon={<img src="/oauth/github.svg" alt="" />}
      color="white"
      onClick={handleClick}
    >
      {actionText} with Github
    </Button>
  );
}
