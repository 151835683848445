import React, { FormEvent, useState } from 'react';
import clsx from 'clsx';
import apiFetch from ':src/utils/apiFetch';
import Button from ':src/components/Button';
import Input from ':src/components/Input';

export type NewsletterSignupProps = {
  defaultEmail?: string;
  standalone?: boolean;
  secondaryButton?: React.ReactNode;
  onSuccess?: () => void;
};

export default function NewsletterSignup({
  defaultEmail = '',
  standalone = false,
  secondaryButton,
  onSuccess,
}: NewsletterSignupProps) {
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState(defaultEmail);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await apiFetch('subscribe', 'POST', {
        email,
      });
      setIsSuccess(true);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const finalClassName = clsx('bg-brand-100 text-brand-900 pt-14 pb-20', {
    'rounded-md': standalone,
  });

  return (
    <div className={finalClassName}>
      <div className="container">
        {!isSuccess && (
          <form className="max-w-2xl" onSubmit={handleSubmit}>
            <fieldset>
              <div className="h3 mb-3">
                Get future questions delivered straight to your inbox for free
              </div>
              <p className="mb-4 text-opacity-70 text-brand-900">
                Sign up and receive instant access to new questions when we
                publish them.
              </p>
              {hasError && (
                <div className="alert alert-danger">
                  Sorry, something went wrong with your submission. Please try
                  again later!
                </div>
              )}
              <label htmlFor="email" className="mb-1 block">
                Email address
              </label>
              <div className="mb-4">
                <div className="flex">
                  <Input
                    type="email"
                    id="email"
                    className="h-12 flex-grow"
                    isAttached
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button isAttached type="submit" disabled={isSubmitting}>
                    Sign up
                  </Button>
                </div>
              </div>
              <p className="mb-3 text-opacity-70 text-brand-900 text-sm">
                We‘ll only use your information to deliver new questions and to
                provide you updates about our product. We‘ll never spam you or
                sell your information without your consent. Unsubscribe at any
                time.
              </p>
            </fieldset>
          </form>
        )}
        {isSuccess && !onSuccess && (
          <div>
            <div className="h3 mb-3">You‘re all set!</div>
            <p className="mb-3">
              Thanks for signing up. We‘ll aim to put out a new question weekly.
            </p>
          </div>
        )}
        {secondaryButton && (
          <div className="flex justify-center mt-8">{secondaryButton}</div>
        )}
      </div>
    </div>
  );
}
