import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { User } from ':src/types';
import apiFetch from ':src/utils/apiFetch';

function useUser() {
  const [user, setUser] = useState<null | User>(null);
  const [isUserLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      try {
        setUser(await apiFetch<User>('me'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    user,
    setUser,
    isUserLoading,
  };
}

const UserState = createContainer(useUser);
export default UserState;
