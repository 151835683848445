import Link from 'next/link';
import React from 'react';

const LINKS = [
  { text: 'Contact', href: '/contact' },
  { text: 'Legal', href: '/legal' },
  { text: 'Privacy policy', href: '/privacy' },
];

export default function Footer() {
  return (
    <div className="mt-4 border-t-2 lg:w-3/4 mx-auto">
      <div className="py-2 container max-w-md">
        <div className="grid grid-cols-3 divide-x divide-blue-500 text-center">
          {LINKS.map(({ text, href }) => (
            <Link href={href} key={href}>
              <a>{text}</a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
