import '../styles/globals.scss';
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import * as gtag from ':src/lib/gtag';
import Layout from ':src/components/Layout';
import UserState from ':src/hooks/UserState';
import bugsnag, { ErrorBoundary } from ':src/lib/bugsnag';

import SurveyModal from ':src/components/SurveyModal';
import { SURVEY_LOCALSTORAGE_KEY } from ':src/constants';

const SURVEY_MODAL_POPUP_DELAY_IN_MS = 5 * 60 * 1000;

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const surveyLocalStorageEntry = localStorage.getItem(
      SURVEY_LOCALSTORAGE_KEY,
    );

    try {
      if (!surveyLocalStorageEntry) {
        localStorage.setItem(
          SURVEY_LOCALSTORAGE_KEY,
          JSON.stringify({ start: Date.now() }),
        );
        return;
      }

      const { hide, start } = JSON.parse(surveyLocalStorageEntry) as {
        hide: boolean;
        start: number;
      };

      if (hide) return;

      const timeSinceStartInMs = Date.now() - start;
      const shouldShowSurveyModal =
        timeSinceStartInMs > SURVEY_MODAL_POPUP_DELAY_IN_MS;
      setIsSurveyModalOpen(shouldShowSurveyModal);

      if (shouldShowSurveyModal) {
        gtag.event({
          action: 'modal_open',
          category: 'survey',
          label: 'Open survey modal',
          value: '1',
        });
      }
    } catch (err) {
      bugsnag.notify(err as Error);
    }
  }, [router.asPath]);

  return (
    <ErrorBoundary>
      <UserState.Provider>
        <Layout>
          <Component {...pageProps} />
          <Toaster />
          <SurveyModal
            isOpen={isSurveyModalOpen}
            onRequestClose={() => setIsSurveyModalOpen(false)}
          />
        </Layout>
      </UserState.Provider>
    </ErrorBoundary>
  );
}
export default MyApp;
