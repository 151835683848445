export const GITHUB_STATE_LOCALSTORAGE_KEY = 'github-state';
export const FACEBOOK_STATE_LOCALSTORAGE_KEY = 'facebook-state';
export const SURVEY_LOCALSTORAGE_KEY = 'survey';

export enum DIFFICULTY {
  All = 'All',
  Hard = 'Hard',
  Medium = 'Medium',
  Easy = 'Easy',
}
