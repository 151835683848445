import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import useFeatureFlags from ':src/hooks/useFeatureFlags';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { isMVPLaunched } = useFeatureFlags();
  return (
    <>
      <Nav />
      {children}
      {isMVPLaunched && <Footer />}
    </>
  );
}
