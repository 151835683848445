import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { AlertCircle } from 'react-feather';
import Link from 'next/link';
import UserState from ':src/hooks/UserState';
import useFeatureFlags from ':src/hooks/useFeatureFlags';
import apiFetch from ':src/utils/apiFetch';
import UserAvatar from './UserAvatar';
import Menu, { MenuItem } from './Menu';
import GithubButton from './social/GithubButton';

const STROKE_COLOR = '#F87171';

type NavLinkProps = {
  href: string;
  children: React.ReactNode;
};

function NavLink({ href, children }: NavLinkProps) {
  return (
    <Link href={href}>
      <a className="px-2 font-semibold hover:underline block text-current">
        {children}
      </a>
    </Link>
  );
}

export default function Nav() {
  const { pathname } = useRouter();
  const onHomePage = pathname === '/';
  const { user, isUserLoading, setUser } = UserState.useContainer();
  const { isMVPLaunched } = useFeatureFlags();

  const handleLogout = async () => {
    if (!user) return;

    await apiFetch('/sessions', 'DELETE');
    setUser(null);
  };

  return (
    <div className="pb-24">
      <nav className="h-24 absolute top-0 left-0 w-full z-10">
        <div className="py-6 container">
          <div className="flex items-center">
            <Link href="/">
              <a className="w-56 inline-block">
                <img
                  src={onHomePage ? '/brand/logo-white.svg' : '/brand/logo.svg'}
                  alt=""
                />
              </a>
            </Link>

            <ul
              className={clsx(
                'flex items-center space-x-3 ml-4 md:ml-12 mt-1 text-lg',
                {
                  'text-white': onHomePage,
                },
              )}
            >
              <li className="hidden md:block">
                <NavLink href="/">Home</NavLink>
              </li>
              <li>
                <NavLink href="/about">About</NavLink>
              </li>
              <li>
                <NavLink href="/resources">Resources</NavLink>
              </li>
            </ul>
            {isMVPLaunched && !isUserLoading && (
              <div className="ml-auto">
                {!user && <GithubButton actionText="Log in" />}
                {user && (
                  <Menu
                    trigger={
                      <UserAvatar
                        color={onHomePage ? 'white' : 'brand'}
                        imageSrc={user.profilePicUrl}
                      />
                    }
                  >
                    <MenuItem href="/account">Account</MenuItem>
                    <MenuItem
                      href={
                        user.username ? `/profile/${user.username}` : '/account'
                      }
                    >
                      {user.username ? (
                        <span>Profile</span>
                      ) : (
                        <span className="flex">
                          <AlertCircle className="mr-2" color={STROKE_COLOR} />
                          Profile
                        </span>
                      )}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                  </Menu>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
